// Generated by Framer (d339326)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Aq56CBlkh", "hsDW56UrM"];

const serializationHash = "framer-P0i5C"

const variantClassNames = {Aq56CBlkh: "framer-v-4ih0gp", hsDW56UrM: "framer-v-1cstoyq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Close: "hsDW56UrM", Menu: "Aq56CBlkh"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Aq56CBlkh", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Aq56CBlkh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapc2fruv = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {const res = await XKaY3MrGu(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-4ih0gp", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"Aq56CBlkh"} onTap={onTapc2fruv} ref={ref ?? ref1} style={{backgroundColor: "var(--token-9862d623-6517-47a2-b9c2-43eb6484365e, rgb(244, 241, 235))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "inset 0px -4px 0px 0px rgba(0, 0, 0, 0.25)", ...style}} {...addPropertyOverrides({hsDW56UrM: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-4hdnns"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"dyvYZ6eUt"} style={{backgroundColor: "var(--token-9c429d76-8bc0-4d5b-bdfd-4f31eda4275b, rgb(41, 41, 41))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{hsDW56UrM: {rotate: -45}}}/><motion.div className={"framer-z4san2"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"CC3EBxHfw"} style={{backgroundColor: "var(--token-9c429d76-8bc0-4d5b-bdfd-4f31eda4275b, rgb(41, 41, 41))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{hsDW56UrM: {rotate: 45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-P0i5C.framer-a8x4zd, .framer-P0i5C .framer-a8x4zd { display: block; }", ".framer-P0i5C.framer-4ih0gp { cursor: pointer; height: 50px; overflow: hidden; position: relative; width: 50px; will-change: var(--framer-will-change-override, transform); }", ".framer-P0i5C .framer-4hdnns { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(58.33333333333336% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-P0i5C .framer-z4san2 { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(41.66666666666669% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-P0i5C.framer-v-1cstoyq.framer-4ih0gp { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 50px); }", ".framer-P0i5C.framer-v-1cstoyq .framer-4hdnns, .framer-P0i5C.framer-v-1cstoyq .framer-z4san2 { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hsDW56UrM":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqWFghTxrV: React.ComponentType<Props> = withCSS(Component, css, "framer-P0i5C") as typeof Component;
export default FramerqWFghTxrV;

FramerqWFghTxrV.displayName = "Icon";

FramerqWFghTxrV.defaultProps = {height: 50, width: 50};

addPropertyControls(FramerqWFghTxrV, {variant: {options: ["Aq56CBlkh", "hsDW56UrM"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerqWFghTxrV, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})